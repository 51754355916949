import $http from '@/plugins/axios';

export default {

  namespaced: true,

  state: {
    loading: false,
    pages: [],
    usersActivity: [],
  },

  getters: {
    getLoading: (state) => state.loading,
    getPages: (state) => state.pages,
    getUsersActivity: (state) => state.usersActivity,
  },

  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload;
    },

    SET_PAGES(state, payload) {
      state.pages = payload;
    },
  },

  actions: {
    async fetchPages({ commit }) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Analytics({ url: '/', methods: 'POST' });
        commit('SET_PAGES', response.data.data);
        return response;
      } catch (error) {
        commit('SET_LOADING', false);
        throw error;
      }
    },
  },
};
