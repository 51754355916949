import Vue from 'vue';
import Buefy from 'buefy';
import HighchartsVue from 'highcharts-vue';
import VueTelInput from 'vue-tel-input';
import Vue2Filters from 'vue2-filters';

import App from './App.vue';
import router from './router';
import store from './store';

import '@/plugins/vee-validate';
import '@/assets/scss/app.scss';

Vue.use(Buefy, {
  defaultIconPack: 'fas',
});
Vue.use(HighchartsVue);
Vue.use(VueTelInput);
Vue.use(Vue2Filters);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
