import axios from 'axios';

import getConfig from '@/plugins/config';

import router from '@/router';

const VUE_APP_AUTHENTICATION_BASE_URL = getConfig('VUE_APP_AUTHENTICATION_BASE_URL');
const VUE_APP_SECURITY_BASE_URL = getConfig('VUE_APP_SECURITY_BASE_URL');
const VUE_APP_ORGANIZATIONS_BASE_URL = getConfig('VUE_APP_ORGANIZATIONS_BASE_URL');
const VUE_APP_USERS_BASE_URL = getConfig('VUE_APP_USERS_BASE_URL');
const VUE_APP_LOOKUPS_BASE_URL = getConfig('VUE_APP_LOOKUPS_BASE_URL');
const VUE_APP_ANALYTICS_BASE_URL = getConfig('VUE_APP_ANALYTICS_BASE_URL');
const VUE_APP_CLIENT_ID = getConfig('VUE_APP_CLIENT_ID');
const VUE_APP_CLIENT_SECRET = getConfig('VUE_APP_CLIENT_SECRET');

const basicToken = Buffer.from(`${VUE_APP_CLIENT_ID}:${VUE_APP_CLIENT_SECRET}`).toString('base64');

const $http = {
  Auth: axios.create({
    baseURL: VUE_APP_AUTHENTICATION_BASE_URL,
    headers: {
      Authorization: `Basic ${basicToken}`,
    },
  }),

  Security: axios.create({
    baseURL: VUE_APP_SECURITY_BASE_URL,
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  }),

  Organizations: axios.create({
    baseURL: VUE_APP_ORGANIZATIONS_BASE_URL,
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  }),

  Users: axios.create({
    baseURL: VUE_APP_USERS_BASE_URL,
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  }),

  Lookups: axios.create({
    baseURL: VUE_APP_LOOKUPS_BASE_URL,
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  }),

  Analytics: axios.create({
    baseURL: VUE_APP_ANALYTICS_BASE_URL,
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  }),
};

const getAccessToken = () => localStorage.getItem('access_token');

const authInterceptor = (config) => {
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${getAccessToken()}`;
  return config;
};

$http.Security.interceptors.request.use(authInterceptor);
$http.Security.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    localStorage.clear();
    router.push('/');
  } else if (error.response.status === 500) {
    router.push('/internal-server-error');
    return Promise.resolve(error.response);
  }
  return Promise.reject(error);
});

$http.Organizations.interceptors.request.use(authInterceptor);
$http.Organizations.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    localStorage.clear();
    router.push('/');
  } else if (error.response.status === 500) {
    router.push('/internal-server-error');
    return Promise.resolve(error.response);
  }
  return Promise.reject(error);
});

$http.Users.interceptors.request.use(authInterceptor);
$http.Users.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    localStorage.clear();
    router.push('/');
  } else if (error.response.status === 500) {
    router.push('/internal-server-error');
    return Promise.resolve(error.response);
  }
  return Promise.reject(error);
});

$http.Lookups.interceptors.request.use(authInterceptor);
$http.Lookups.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    localStorage.clear();
    router.push('/');
  } else if (error.response.status === 500) {
    router.push('/internal-server-error');
    return Promise.resolve(error.response);
  }
  return Promise.reject(error);
});

$http.Analytics.interceptors.request.use(authInterceptor);
$http.Analytics.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    localStorage.clear();
    router.push('/');
  } else if (error.response.status === 500) {
    router.push('/internal-server-error');
    return Promise.resolve(error.response);
  }
  return Promise.reject(error);
});

export default $http;
