import $http from '@/plugins/axios';

export default {
  namespaced: true,

  state: {
    isLoading: false,

    organizations: [],

    organization: {
      isActive: true,
      name: '',
      primaryUserId: '',
      primaryUser: '',
      timeZoneId: '',
      street1: '',
      street2: '',
      street3: '',
      city: '',
      state: '',
      zipCode: '',
      locations: [],
      createdAt: '',
      updatedAt: '',
    },

    location: {
      name: '',
      timezoneId: '',
      isActive: true,
    },

    currentRecord: 0,
    lastRecord: 0,
    totalRecords: 0,
  },

  getters: {
    getLoading: (state) => state.isLoading,
    getOrganizations: (state) => {
      const data = state.organizations.slice(0);
      return data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    },
    getOrganization: (state) => state.organization,
    getLocation: (state) => state.location,
    getOrganizationLocations: (state) => state.organization.locations,
    getCurrentRecord: (state) => state.currentRecord,
    getLastRecord: (state) => state.lastRecord,
    getTotalRecords: (state) => state.totalRecords,
  },

  mutations: {
    SET_LOADING(state, payload) {
      state.isLoading = payload;
    },

    SET_ORGANIZATIONS(state, payload) {
      state.organizations = payload;
    },

    SET_ORGANIZATION_IS_ACTIVE(state, payload) {
      state.organization.isActive = payload;
    },

    SET_ORGANIZATION_NAME(state, payload) {
      state.organization.name = payload;
    },

    SET_ORGANIZATION_PRIMARY_USER(state, payload) {
      state.organization.primaryUser = payload;
    },

    SET_ORGANIZATION_PRIMARY_USER_ID(state, payload) {
      state.organization.primaryUserId = payload;
    },

    SET_ORGANIZATION_TIME_ZONE_ID(state, payload) {
      state.organization.timeZoneId = payload;
    },

    SET_ORGANIZATION_ADDRESS_STREET_1(state, payload) {
      state.organization.street1 = payload;
    },

    SET_ORGANIZATION_ADDRESS_STREET_2(state, payload) {
      state.organization.street2 = payload;
    },

    SET_ORGANIZATION_ADDRESS_STREET_3(state, payload) {
      state.organization.street3 = payload;
    },

    SET_ORGANIZATION_ADDRESS_CITY(state, payload) {
      state.organization.city = payload;
    },

    SET_ORGANIZATION_ADDRESS_STATE(state, payload) {
      state.organization.state = payload;
    },

    SET_ORGANIZATION_ADDRESS_ZIP_CODE(state, payload) {
      state.organization.zipCode = payload;
    },

    SET_ORGANIZATION(state, payload) {
      state.organization = {
        isActive: payload.isActive,
        name: payload.name,
        primaryUserId: payload.primaryUserId,
        primaryUser: `${payload.primaryUser.firstName} ${payload.primaryUser.middleName} ${payload.primaryUser.lastName}`,
        timeZoneId: payload.timeZoneId,
        street1: payload.street1,
        street2: payload.street2,
        street3: payload.street3,
        city: payload.city,
        state: payload.state,
        zipCode: payload.zipCode,
        locations: payload.locations.length !== null ? payload.locations : [],
        createdAt: payload.createdAt,
        updatedAt: payload.updatedAt,
      };
    },

    DELETE_ORGANIZATION(state, payload) {
      const index = state.organizations.findIndex((organization) => organization.id === payload);
      if (index === 0) state.organizations.shift();
      state.organizations.splice(index, 1);
    },

    CLEAR_ORGANIZATION(state) {
      state.organization = {
        isActive: true,
        name: '',
        primaryUserId: '',
        primaryUser: '',
        timeZoneId: '',
        street1: '',
        street2: '',
        street3: '',
        city: '',
        state: '',
        zipCode: '',
        locations: [],
        createdAt: '',
        updatedAt: '',
      };
    },

    SET_LOCATION_NAME(state, payload) {
      state.location.name = payload;
    },

    SET_LOCATION_TIMEZONE_ID(state, payload) {
      state.location.timezoneId = payload;
    },

    SET_LOCATION_IS_ACTIVE(state, payload) {
      state.location.isActive = payload;
    },

    CLEAR_LOCATION(state) {
      state.location = {
        name: '',
        timezoneId: '',
        isActive: true,
      };
    },

    ADD_ORGANIZATION_LOCATION(state, payload) {
      state.organization.locations.push(payload);
    },

    REMOVE_ORGANIZATION_LOCATION(state, payload) {
      const index = state.organization.locations.indexOf(payload);
      if (index === 0) state.organization.locations.shift();
      state.organization.locations.splice(index, 1);
    },
  },

  actions: {
    async fetchOrganizations({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Organizations({
          url: `?page=${payload.page}&size=${payload.perPage}`,
          method: 'GET',
        });
        commit('SET_ORGANIZATIONS', response.data.content);
        return response.data;
      } catch (error) {
        throw error.response.data;
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async fetchOrganization({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Organizations({ url: `/${payload}`, method: 'GET' });
        commit('SET_ORGANIZATION', response.data.data);
        return response.data.data;
      } catch (error) {
        throw error.response.data;
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async createOrganization({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Organizations({ url: '', data: payload, method: 'POST' });
        return response.data.data;
      } catch (error) {
        throw error.response.data;
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async updateOrganization({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Organizations({
          url: `/${payload.organizationId}`,
          data: payload.payload,
          method: 'PUT',
        });
        return response.data.data;
      } catch (error) {
        throw error.response.data;
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async deleteOrganization({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Organizations({ url: `/${payload}`, method: 'DELETE' });
        commit('DELETE_ORGANIZATION', payload);
        return response.data.data;
      } catch (error) {
        throw error.response.data;
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async createOrganizationLocation({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Organizations({
          url: `${payload.organizationId}/locations`,
          data: payload.location,
          method: 'PUT',
        });
        return response.data.data;
      } catch (error) {
        throw error.response.data;
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async updateOrganizationLocation({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Organizations({
          url: `${payload.organizationId}/locations/${payload.data.id}`,
          data: payload.data,
          method: 'PUT',
        });
        return response.data.data;
      } catch (error) {
        throw error.response.data;
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async deleteOrganizationLocation({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Organizations({
          url: `${payload.organizationId}/locations/${payload.locationId}`,
          method: 'DELETE',
        });
        return response.data.data;
      } catch (error) {
        throw error.response.data;
      } finally {
        commit('SET_LOADING', false);
      }
    },

  },

};
