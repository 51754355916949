import $http from '../plugins/axios';

export default {
  namespaced: true,

  state: {
    isLoading: false,
    selectedRole: {},
    role: {
      id: '',
      name: '',
      description: '',
      permissions: [],
    },
    roles: [],
  },

  getters: {
    getLoading: (state) => state.isLoading,
    getRoles: (state) => state.roles,
    getRole: (state) => state.role,
    getSelectedRole: (state) => state.selectedRole,
  },

  mutations: {
    SET_LOADING(state, payload) {
      state.isLoading = payload;
    },

    SET_SELECTED_ROLE(state, payload) {
      state.selectedRole = payload;
    },

    SET_SELECTED_ROLE_NAME(state, payload) {
      state.selectedRole.name = payload;
    },

    SET_ROLE_NAME(state, payload) {
      state.role.name = payload;
    },

    SET_ROLE_DESCRIPTION(state, payload) {
      state.role.description = payload;
    },

    SET_ROLES(state, payload) {
      state.roles = payload;
    },

    SET_ROLE(state, payload) {
      state.role.id = payload.id;
      state.role.name = payload.name.replace(/_/g, ' ').substring(5).toLowerCase();
      state.role.description = payload.description;
      state.role.permissions = payload.permissions;
    },

    DELETE_ROLE(state, payload) {
      const index = state.roles.findIndex((role) => role.name === payload);
      if (index === 0) state.roles.shift();
      state.roles.splice(index, 1);
    },

    CLEAR_ROLE(state) {
      state.role = {
        id: '',
        name: '',
        description: '',
        permissions: [],
      };
    },

  },

  actions: {
    async fetchRoles({ commit }) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Security({ url: '/roles/getRoles', method: 'GET' });
        commit('SET_LOADING', false);
        commit('SET_ROLES', response.data.message);
        return response.data;
      } catch (error) {
        commit('SET_LOADING', false);
        throw error.response.data;
      }
    },

    async fetchRole({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Security({
          url: `/roles/getRole?name=${payload}`,
          method: 'GET',
        });
        commit('SET_LOADING', false);
        commit('SET_ROLE', response.data.message);
        return response.data;
      } catch (error) {
        commit('SET_LOADING', false);
        throw error.response.data;
      }
    },

    async createRole({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Security({ url: '/roles/add', data: payload, method: 'POST' });
        commit('SET_LOADING', false);
        return response.data;
      } catch (error) {
        commit('SET_LOADING', false);
        throw error.response.data;
      }
    },

    async updateRole({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Security({
          url: '/roles/update',
          data: payload,
          method: 'PUT',
        });
        commit('SET_LOADING', false);
        return response.data;
      } catch (error) {
        commit('SET_LOADING', false);
        throw error.response.data;
      }
    },

    async deleteRole({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Security({
          url: `/roles/delete?name=${payload}`,
          method: 'GET',
        });
        commit('DELETE_ROLE', payload);
        commit('SET_LOADING', false);
        return response;
      } catch (error) {
        commit('SET_LOADING', false);
        throw error.response.data;
      }
    },

    async assignRole({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Security({
          url: '/roles/assign',
          data: payload,
          method: 'POST',
        });
        commit('SET_LOADING', false);
        return response.data.data;
      } catch (error) {
        commit('SET_LOADING', false);
        throw error.response.data;
      }
    },

    async revokeRole({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Security({
          url: '/roles/deassign',
          data: payload,
          method: 'POST',
        });
        commit('SET_LOADING', false);
        return response.data.data;
      } catch (error) {
        commit('SET_LOADING', false);
        throw error.response.data;
      }
    },
  },

};
