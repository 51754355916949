import $http from '@/plugins/axios';

export default {
  namespaced: true,

  state: {
    isLoading: false,
    users: [],
    user: {
      userPhoto: '',
      username: '',
      password: '',
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      phoneCell: '',
      externalId: '',
      roles: ['ROLE_BUSINESS_ADMIN'],
      managerId: '',
      teamId: '',
      divisionId: '',
      resetPassword: true,
      isActive: true,
    },
  },

  getters: {
    getUsers: (state) => {
      const data = [...state.users];
      return data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    },
    getOrganizationFormUsers: (state) => {
      const data = [...state.users];
      return data.filter((user) => !user.orgId);
    },
    getUser: (state) => state.user,
    getLoading: (state) => state.isLoading,
  },

  mutations: {
    SET_LOADING(state, payload) {
      state.isLoading = payload;
    },

    CLEAR_USER(state) {
      state.user = {
        userPhoto: '',
        username: '',
        password: '',
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        phoneCell: '',
        externalId: '',
        roles: ['ROLE_BUSINESS_ADMIN'],
        managerId: '',
        teamId: '',
        divisionId: '',
        resetPassword: true,
        isActive: true,
      };
    },

    SET_USERS(state, payload) {
      state.users = payload.content.sort((a, b) => b.id - a.id).map((user) => ({
        id: user.id,
        orgId: user.orgId,
        username: user.username,
        firstName: user.firstName,
        middleName: user.middleName,
        lastName: user.lastName,
        fullName: `${user.firstName} ${user.middleName} ${user.lastName}`,
        email: user.email,
        organization: user.organizationUsersEntity ? user.organizationUsersEntity : null,
        role: user.role.length > 0 && Object.keys(user.role[0]).includes('name') ? user.role[0].name : null,
        userPhoto: user.userPhoto,
        phone: user.phoneCell,
      }));
    },

    SET_USER(state, payload) {
      state.user.userPhoto = payload.userPhoto;
      state.user.username = payload.username;
      state.user.firstName = payload.firstName;
      state.user.middleName = payload.middleName;
      state.user.lastName = payload.lastName;
      state.user.email = payload.email;
      state.user.phoneCell = payload.phoneCell;
      state.user.externalId = payload.externalId;
      state.user.roles = [payload.role[0].name];
      state.user.isActive = payload.isActive;
    },

    SET_USER_USER_PHOTO(state, payload) {
      state.user.userPhoto = payload;
    },

    SET_USER_USERNAME(state, payload) {
      state.user.username = payload;
    },

    SET_USER_PASSWORD(state, payload) {
      state.user.password = payload;
    },

    SET_USER_FIRST_NAME(state, payload) {
      state.user.firstName = payload;
    },

    SET_USER_MIDDLE_NAME(state, payload) {
      state.user.middleName = payload;
    },

    SET_USER_LAST_NAME(state, payload) {
      state.user.lastName = payload;
    },

    SET_USER_EMAIL(state, payload) {
      state.user.email = payload;
    },

    SET_USER_PHONE_CELL(state, payload) {
      state.user.phoneCell = payload;
    },

    SET_USER_EXTERNAL_ID(state, payload) {
      state.user.externalId = payload;
    },

    SET_USER_MANAGER_ID(state, payload) {
      state.user.managerId = payload;
    },

    SET_USER_RESET_PASSWORD(state, payload) {
      state.user.resetPassword = payload;
    },

    SET_USER_IS_ACTIVE(state, payload) {
      state.user.isActive = payload;
    },
  },

  actions: {
    async fetchUsers({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Users({
          url: `/fetchAll?page=${payload.page}&size=${payload.perPage}`,
          method: 'GET',
        });
        commit('SET_USERS', response.data.message);
        return response.data.message;
      } catch (error) {
        throw error.response.data;
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async fetchUser({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Users({
          url: `/fetchById?id=${payload}`,
          method: 'GET',
        });
        commit('SET_USER', response.data.message[0]);
        return response;
      } catch (error) {
        throw error.response.data;
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async createUser({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Users({
          url: '/signup',
          data: payload,
          method: 'POST',
        });
        return response;
      } catch (error) {
        throw error.response.data;
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async updateUser({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Users({
          url: 'update',
          data: payload,
          method: 'POST',
        });
        return response;
      } catch (error) {
        throw error.response.data;
      } finally {
        commit('SET_LOADING', false);
      }
    },
  },

};
