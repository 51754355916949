import $http from '@/plugins/axios';

export default {
  namespaced: true,

  state: {
    loading: false,
    lookups: [],
    timezones: [],
    industries: [],
    locations: [],
  },

  getters: {
    getLoading: (state) => state.loading,
    getLocations: (state) => state.lookups.filter((element) => element.name === 'location'),
    getTeams: (state) => state.lookups.filter((element) => element.name === 'team'),
    getDivisions: (state) => state.lookups.filter((element) => element.name === 'division'),
    getTimezones: (state) => state.timezones,
  },

  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload;
    },

    SET_LOOKUPS(state, payload) {
      state.lookups = payload;
    },

    SET_TIMEZONES(state, payload) {
      state.timezones = payload;
    },
  },

  actions: {
    async fetchLookups({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Lookups({ url: `/${payload}`, method: 'GET' });
        commit('SET_LOADING', false);
        commit('SET_LOOKUPS', response.data.data);
        return response.data;
      } catch (error) {
        commit('SET_LOADING', false);
        return false;
      }
    },

    async fetchTimezones({ commit }) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Lookups({ url: '/timezone', method: 'GET' });
        commit('SET_LOADING', false);
        commit('SET_TIMEZONES', response.data.data);
        return response.data;
      } catch (error) {
        commit('SET_LOADING', false);
        return false;
      }
    },
  },
};
