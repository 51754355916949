import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Authentication/Login.vue'),
  }, {
    path: '/permissions',
    name: 'Permissions',
    component: () => import(/* webpackChunkName: "permissions" */ '../views/Permissions.vue'),
    meta: {
      requiresAuth: true,
    },
  }, {
    path: '/roles',
    name: 'Roles',
    component: () => import(/* webpackChunkName: "roles" */ '../views/Roles.vue'),
    meta: {
      requiresAuth: true,
    },
  }, {
    path: '/roles-assignment',
    name: 'RolesAssignment',
    component: () => import(
      /* webpackChunkName: "roles-assignment" */ '../views/RolesAssignment.vue'
    ),
    meta: {
      requiresAuth: true,
    },
  }, {
    path: '/organizations',
    component: () => import('../views/Organizations/Index.vue'),
    children: [
      {
        path: '',
        name: 'ListOrganizations',
        component: () => import(
          /* webpackChunkName: "list-organizations" */ '../views/Organizations/ListOrganizations.vue'
        ),
        meta: {
          requiresAuth: true,
        },
      }, {
        path: 'add',
        name: 'AddOrganization',
        component: () => import(
          /* webpackChunkName: "add-organization" */ '../views/Organizations/AddOrganization.vue'
        ),
        meta: {
          requiresAuth: true,
        },
      }, {
        path: ':organizationId/edit',
        name: 'EditOrganization',
        component: () => import(
          /* webpackChunkName: "edit-organization" */ '../views/Organizations/EditOrganization.vue'
        ),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  }, {
    path: '/users',
    component: () => import('../views/Users/Index.vue'),
    children: [
      {
        path: '',
        name: 'Users',
        component: () => import(/* webpackChunkName: "list-users" */ '../views/Users/ListUsers.vue'),
        meta: {
          requiresAuth: true,
        },
      }, {
        path: 'add',
        name: 'AddUser',
        component: () => import(/* webpackChunkName: "add-user" */ '../views/Users/AddUser.vue'),
        meta: {
          requiresAuth: true,
        },
      }, {
        path: ':userId/edit',
        name: 'EditUser',
        component: () => import(/* webpackChunkName: "edit-user" */ '../views/Users/EditUser.vue'),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  }, {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
    meta: {
      requiresAuth: true,
    },
  }, {
    path: '*',
    name: 'PageNotFound',
    component: () => import(
      /* webpackChunkName: "page-not-found" */ '../views/Errors/PageNotFound.vue'
    ),
    meta: {
      requiresAuth: true,
    },
  }, {
    path: '/internal-server-error',
    name: 'InternalServerError',
    component: () => import(
      /* webpackChunkName: "internal-server-error" */ '../views/Errors/InternalServerError.vue'
    ),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem('access_token')) {
      if (localStorage.getItem('role') === 'ROLE_SYSTEM_ADMIN') {
        next();
      } else {
        const token = localStorage.getItem('access_token');
        const email = localStorage.getItem('email');
        localStorage.clear();
        window.open(`http://mxc.primedevs.io/redirect/${token}/${email}`, '_self');
      }
    } else {
      next('/');
    }
  } else {
    next();
  }
});

export default router;
