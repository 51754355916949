import $http from '@/plugins/axios';

export default {

  namespaced: true,

  state: {
    isLoading: false,
    token: localStorage.getItem('access_token') || '',
    role: localStorage.getItem('role') || '',
    credentials: {
      username: '',
      password: '',
    },
    userPhoto: '',
  },

  getters: {
    getLoading: (state) => state.isLoading,
    getToken: (state) => state.token,
    getRole: (state) => state.role,
    getCredentials: (state) => state.credentials,
    getUserPhoto: (state) => state.userPhoto,
  },

  mutations: {
    SET_LOADING(state, payload) {
      state.isLoading = payload;
    },

    SET_TOKEN(state, payload) {
      localStorage.setItem('access_token', payload);
      state.token = payload;
    },

    SET_ROLE(state, payload) {
      localStorage.setItem('role', payload);
      state.role = payload;
    },

    SET_USER_PHOTO(state, payload) {
      localStorage.setItem('user_photo', payload);
      state.userPhoto = payload;
    },

    SET_CREDENTIALS_USERNAME(state, payload) {
      state.credentials.username = payload;
    },

    SET_CREDENTIALS_PASSWORD(state, payload) {
      state.credentials.password = payload;
    },

    CLEAR_CREDENTIALS(state) {
      state.credentials = {
        username: '',
        password: '',
      };
    },

    REMOVE_TOKEN(state, payload) {
      localStorage.removeItem('access_token');
      state.token = payload;
    },
  },

  actions: {
    async login({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Auth({
          headers: { 'Content-Type': 'multipart/form-data' },
          url: '/oauth/token',
          data: payload.payload,
          method: 'POST',
        });
        commit('SET_LOADING', false);
        if (Object.keys(response.data).includes('access_token')) {
          commit('SET_TOKEN', response.data.access_token);
        }
        localStorage.setItem('email', payload.username);
        const userCredentials = await $http.Users({
          headers: {
            Authorization: `Bearer ${response.data.access_token}`,
          },
          url: `/fetch?email=${payload.username}`,
          method: 'GET',
        });
        commit('SET_ROLE', userCredentials.data.message.role[0].name);
        commit('SET_USER_PHOTO', userCredentials.data.message.userPhoto);
        return response.data;
      } catch (error) {
        commit('SET_LOADING', false);
        commit('REMOVE_TOKEN', '');
        localStorage.clear();
        throw error.response.data;
      }
    },

    logout({ commit }) {
      commit('REMOVE_TOKEN', '');
      localStorage.clear();
    },
  },

};
