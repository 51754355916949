import permissionsData from '@/static/permissions.json';
import $http from '../plugins/axios';

const findPermission = (name) => permissionsData.find((element) => element.name === name);

const bindPermission = (state, payload, obj) => {
  if (payload === true) {
    state.postPermissions.push(obj);
  } else if (payload === false) {
    const index = state.postPermissions.findIndex((permission) => permission.name === obj.name);
    if (index === 0) state.postPermissions.shift();
    state.postPermissions.splice(index, 1);
  }
};

export default {
  namespaced: true,

  state: {
    isLoading: false,
    postPermissions: [],
    permissions: {
      accounts: {
        view: '', create: '', edit: '', delete: '',
      },
      contacts: {
        view: '', create: '', edit: '', delete: '',
      },
      leads: {
        view: '', create: '', edit: '', delete: '',
      },
      opportunities: {
        view: '', create: '', edit: '', delete: '',
      },
      events: {
        view: '', create: '', edit: '', delete: '',
      },
      stages: {
        view: '', create: '', edit: '', delete: '',
      },
      sales: {
        view: '', create: '', edit: '', delete: '',
      },
      reports: {
        view: '', create: '', edit: '', delete: '',
      },
      goals: {
        view: '', create: '', edit: '', delete: '',
      },
      lookups: {
        view: '', create: '', edit: '', delete: '',
      },
      activities: {
        view: '', create: '', edit: '', delete: '',
      },
      documents: {
        view: '', create: '', edit: '', delete: '',
      },
      project_folders: {
        view: '', create: '', edit: '',
      },
      order_quote_financials: {
        create: '', edit: '',
      },
      admin: {
        settings: '',
        connectors: '',
        fields: '',
        customFields: {
          create: '', edit: '', delete: '',
        },
        roles: {
          create: '', edit: '', delete: '',
        },
        organizations: {
          view: '', create: '', edit: '', delete: '',
        },
        teams: {
          view: '', create: '', edit: '', delete: '',
        },
        divisions: {
          view: '', create: '', edit: '', delete: '',
        },
        users: {
          create: '', edit: '', delete: '',
        },
      },
    },
  },

  getters: {
    getLoading: (state) => state.isLoading,
    getPermissions: (state) => state.permissions,
    getPostPermissions: (state) => state.postPermissions,
  },

  mutations: {
    SET_LOADING(state, payload) {
      state.isLoading = payload;
    },

    SET_PERMISSIONS(state, payload) {
      const newArr = payload.map((arr) => arr.name);
      const permissions = {
        accounts: {
          view: newArr.find((element) => element === 'read_accounts') ? 'true' : 'false',
          create: newArr.find((element) => element === 'create_accounts') ? 'true' : 'false',
          edit: newArr.find((element) => element === 'update_accounts') ? 'true' : 'false',
          delete: newArr.find((element) => element === 'delete_accounts') ? 'true' : 'false',
        },
        contacts: {
          view: newArr.find((element) => element === 'read_contacts') ? 'true' : 'false',
          create: newArr.find((element) => element === 'create_contacts') ? 'true' : 'false',
          edit: newArr.find((element) => element === 'update_contacts') ? 'true' : 'false',
          delete: newArr.find((element) => element === 'delete_contacts') ? 'true' : 'false',
        },
        leads: {
          view: newArr.find((element) => element === 'read_leads') ? 'true' : 'false',
          create: newArr.find((element) => element === 'read_leads') ? 'true' : 'false',
          edit: newArr.find((element) => element === 'read_leads') ? 'true' : 'false',
          delete: newArr.find((element) => element === 'read_leads') ? 'true' : 'false',
        },
        opportunities: {
          view: newArr.find((element) => element === 'read_opportunities') ? 'true' : 'false',
          create: newArr.find((element) => element === 'read_opportunities') ? 'true' : 'false',
          edit: newArr.find((element) => element === 'read_opportunities') ? 'true' : 'false',
          delete: newArr.find((element) => element === 'read_opportunities') ? 'true' : 'false',
        },
        events: {
          view: newArr.find((element) => element === 'read_events') ? 'true' : 'false',
          create: newArr.find((element) => element === 'create_events') ? 'true' : 'false',
          edit: newArr.find((element) => element === 'update_events') ? 'true' : 'false',
          delete: newArr.find((element) => element === 'delete_events') ? 'true' : 'false',
        },
        stages: {
          view: newArr.find((element) => element === 'read_stages') ? 'true' : 'false',
          create: newArr.find((element) => element === 'create_stages') ? 'true' : 'false',
          edit: newArr.find((element) => element === 'update_stages') ? 'true' : 'false',
          delete: newArr.find((element) => element === 'delete_stages') ? 'true' : 'false',
        },
        sales: {
          view: newArr.find((element) => element === 'read_sales') ? 'true' : 'false',
          create: newArr.find((element) => element === 'create_sales') ? 'true' : 'false',
          edit: newArr.find((element) => element === 'update_sales') ? 'true' : 'false',
          delete: newArr.find((element) => element === 'delete_sales') ? 'true' : 'false',
        },
        reports: {
          view: newArr.find((element) => element === 'read_reports') ? 'true' : 'false',
          create: newArr.find((element) => element === 'create_reports') ? 'true' : 'false',
          edit: newArr.find((element) => element === 'update_reports') ? 'true' : 'false',
          delete: newArr.find((element) => element === 'delete_reports') ? 'true' : 'false',
        },
        goals: {
          view: newArr.find((element) => element === 'read_quotas') ? 'true' : 'false',
          create: newArr.find((element) => element === 'create_quotas') ? 'true' : 'false',
          edit: newArr.find((element) => element === 'update_quotas') ? 'true' : 'false',
          delete: newArr.find((element) => element === 'delete_quotas') ? 'true' : 'false',
        },
        lookups: {
          view: newArr.find((element) => element === 'read_lookups') ? 'true' : 'false',
          create: newArr.find((element) => element === 'create_lookups') ? 'true' : 'false',
          edit: newArr.find((element) => element === 'update_lookups') ? 'true' : 'false',
          delete: newArr.find((element) => element === 'delete_lookups') ? 'true' : 'false',
        },
        activities: {
          view: newArr.find((element) => element === 'read_activities') ? 'true' : 'false',
          create: newArr.find((element) => element === 'create_activities') ? 'true' : 'false',
          edit: newArr.find((element) => element === 'update_activities') ? 'true' : 'false',
          delete: newArr.find((element) => element === 'delete_activities') ? 'true' : 'false',
        },
        documents: {
          view: newArr.find((element) => element === 'read_documents') ? 'true' : 'false',
          create: newArr.find((element) => element === 'create_documents') ? 'true' : 'false',
          edit: newArr.find((element) => element === 'update_documents') ? 'true' : 'false',
          delete: newArr.find((element) => element === 'delete_documents') ? 'true' : 'false',
        },
        project_folders: {
          view: newArr.find((element) => element === 'read_project_folders') ? 'true' : 'false',
          create: newArr.find((element) => element === 'create_project_folders') ? 'true' : 'false',
          edit: newArr.find((element) => element === 'update_project_folders') ? 'true' : 'false',
        },
        order_quote_financials: {
          create: newArr.find((element) => element === 'create_order_quote_financials') ? 'true' : 'false',
          edit: newArr.find((element) => element === 'update_order_quote_financials') ? 'true' : 'false',
        },
        admin: {
          settings: newArr.find((element) => element === 'settings') ? 'true' : 'false',
          connectors: newArr.find((element) => element === 'connectors') ? 'true' : 'false',
          fields: newArr.find((element) => element === 'fields') ? 'true' : 'false',
          customFields: {
            create: newArr.find((element) => element === 'create_customefields') ? 'true' : 'false',
            edit: newArr.find((element) => element === 'update_customefields') ? 'true' : 'false',
            delete: newArr.find((element) => element === 'delete_customefields') ? 'true' : 'false',
          },
          roles: {
            create: newArr.find((element) => element === 'create_roles') ? 'true' : 'false',
            edit: newArr.find((element) => element === 'update_roles') ? 'true' : 'false',
            delete: newArr.find((element) => element === 'delete_roles') ? 'true' : 'false',
          },
          organizations: {
            view: newArr.find((element) => element === 'read_organizations') ? 'true' : 'false',
            create: newArr.find((element) => element === 'create_organizations') ? 'true' : 'false',
            edit: newArr.find((element) => element === 'update_organizations') ? 'true' : 'false',
            delete: newArr.find((element) => element === 'delete_organizations') ? 'true' : 'false',
          },
          teams: {
            read: newArr.find((element) => element === 'read_teams') ? 'true' : 'false',
            create: newArr.find((element) => element === 'create_teams') ? 'true' : 'false',
            edit: newArr.find((element) => element === 'update_teams') ? 'true' : 'false',
            delete: newArr.find((element) => element === 'delete_teams') ? 'true' : 'false',
          },
          divisions: {
            read: newArr.find((element) => element === 'read_divisions') ? 'true' : 'false',
            create: newArr.find((element) => element === 'create_divisions') ? 'true' : 'false',
            edit: newArr.find((element) => element === 'update_divisions') ? 'true' : 'false',
            delete: newArr.find((element) => element === 'delete_divisions') ? 'true' : 'false',
          },
          users: {
            create: newArr.find((element) => element === 'create_users') ? 'true' : 'false',
            edit: newArr.find((element) => element === 'update_users') ? 'true' : 'false',
            delete: newArr.find((element) => element === 'delete_users') ? 'true' : 'false',
          },
        },
      };

      state.permissions = permissions;
    },

    SET_POST_PERMISSIONS(state, payload) {
      state.postPermissions = payload;
    },

    SET_CONTACTS_VIEW_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('read_contacts'));
      state.permissions.contacts.view = payload;
    },

    SET_CONTACTS_CREATE_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('create_contacts'));
      state.permissions.contacts.create = payload;
    },

    SET_CONTACTS_EDIT_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('update_contacts'));
      state.permissions.contacts.edit = payload;
    },

    SET_CONTACTS_DELETE_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('delete_contacts'));
      state.permissions.contacts.delete = payload;
    },

    SET_LEADS_VIEW_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('read_leads'));
      state.permissions.leads.view = payload;
    },

    SET_LEADS_CREATE_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('create_leads'));
      state.permissions.leads.create = payload;
    },

    SET_LEADS_EDIT_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('update_leads'));
      state.permissions.leads.edit = payload;
    },

    SET_LEADS_DELETE_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('delete_leads'));
      state.permissions.leads.delete = payload;
    },

    SET_OPPORTUNITIES_VIEW_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('read_opportunities'));
      state.permissions.opportunities.view = payload;
    },

    SET_OPPORTUNITIES_CREATE_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('create_opportunities'));
      state.permissions.opportunities.create = payload;
    },

    SET_OPPORTUNITIES_EDIT_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('update_opportunities'));
      state.permissions.opportunities.edit = payload;
    },

    SET_OPPORTUNITIES_DELETE_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('delete_opportunities'));
      state.permissions.opportunities.delete = payload;
    },

    SET_STAGES_VIEW_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('read_stages'));
      state.permissions.stages.view = payload;
    },

    SET_STAGES_CREATE_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('create_stages'));
      state.permissions.stages.create = payload;
    },

    SET_STAGES_EDIT_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('update_stages'));
      state.permissions.stages.edit = payload;
    },

    SET_STAGES_DELETE_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('delete_stages'));
      state.permissions.stages.delete = payload;
    },

    SET_EVENTS_VIEW_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('read_events'));
      state.permissions.events.view = payload;
    },

    SET_EVENTS_CREATE_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('create_events'));
      state.permissions.events.create = payload;
    },

    SET_EVENTS_EDIT_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('update_events'));
      state.permissions.events.edit = payload;
    },

    SET_EVENTS_DELETE_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('delete_events'));
      state.permissions.events.delete = payload;
    },

    SET_SALES_VIEW_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('read_sales'));
      state.permissions.sales.view = payload;
    },

    SET_SALES_CREATE_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('create_sales'));
      state.permissions.sales.create = payload;
    },

    SET_SALES_EDIT_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('update_sales'));
      state.permissions.sales.edit = payload;
    },

    SET_SALES_DELETE_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('delete_sales'));
      state.permissions.sales.delete = payload;
    },

    SET_REPORTS_VIEW_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('read_reports'));
      state.permissions.reports.view = payload;
    },

    SET_REPORTS_CREATE_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('create_reports'));
      state.permissions.reports.create = payload;
    },

    SET_REPORTS_EDIT_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('update_reports'));
      state.permissions.reports.edit = payload;
    },

    SET_REPORTS_DELETE_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('delete_reports'));
      state.permissions.reports.delete = payload;
    },

    SET_ACCOUNTS_VIEW_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('read_accounts'));
      state.permissions.accounts.view = payload;
    },

    SET_ACCOUNTS_CREATE_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('create_accounts'));
      state.permissions.accounts.create = payload;
    },

    SET_ACCOUNTS_EDIT_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('update_accounts'));
      state.permissions.accounts.edit = payload;
    },

    SET_ACCOUNTS_DELETE_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('delete_accounts'));
      state.permissions.accounts.delete = payload;
    },

    SET_GOALS_VIEW_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('read_quotas'));
      state.permissions.goals.view = payload;
    },

    SET_GOALS_CREATE_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('create_quotas'));
      state.permissions.goals.create = payload;
    },

    SET_GOALS_EDIT_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('update_quotas'));
      state.permissions.goals.edit = payload;
    },

    SET_GOALS_DELETE_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('delete_quotas'));
      state.permissions.goals.delete = payload;
    },

    SET_LOOKUPS_VIEW_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('read_lookups'));
      state.permissions.lookups.view = payload;
    },

    SET_LOOKUPS_CREATE_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('create_lookups'));
      state.permissions.lookups.create = payload;
    },

    SET_LOOKUPS_EDIT_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('update_lookups'));
      state.permissions.lookups.edit = payload;
    },

    SET_LOOKUPS_DELETE_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('delete_lookups'));
      state.permissions.lookups.delete = payload;
    },

    SET_ACTIVITIES_VIEW_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('read_activities'));
      state.permissions.activities.view = payload;
    },

    SET_ACTIVITIES_CREATE_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('create_activities'));
      state.permissions.activities.create = payload;
    },

    SET_ACTIVITIES_EDIT_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('update_activities'));
      state.permissions.activities.edit = payload;
    },

    SET_ACTIVITIES_DELETE_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('delete_activities'));
      state.permissions.activities.delete = payload;
    },

    SET_DOCUMENTS_VIEW_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('read_documents'));
      state.permissions.documents.view = payload;
    },

    SET_DOCUMENTS_CREATE_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('create_documents'));
      state.permissions.documents.create = payload;
    },

    SET_DOCUMENTS_EDIT_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('update_documents'));
      state.permissions.documents.edit = payload;
    },

    SET_DOCUMENTS_DELETE_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('delete_documents'));
      state.permissions.documents.delete = payload;
    },

    SET_PROJECT_FOLDERS_VIEW_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('read_project_folders'));
      state.permissions.project_folders.view = payload;
    },

    SET_PROJECT_FOLDERS_CREATE_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('create_project_folders'));
      state.permissions.project_folders.create = payload;
    },

    SET_PROJECT_FOLDERS_EDIT_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('update_project_folders'));
      state.permissions.project_folders.edit = payload;
    },

    SET_ORDER_QUOTE_FINANCIALS_CREATE_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('create_order_quote_financials'));
      state.permissions.order_quote_financials.create = payload;
    },

    SET_ORDER_QUOTE_FINANCIALS_EDIT_PERMISSIONS(state, payload) {
      bindPermission(state, payload, findPermission('update_order_quote_financials'));
      state.permissions.order_quote_financials.edit = payload;
    },

    SET_ADMIN_SETTINGS_PERMISSIONS(state, payload) {
      bindPermission(state, payload, { id: 7, name: 'update_contacts' });
      state.permissions.admin.settings = payload;
    },

    SET_ADMIN_CONNECTORS_PERMISSIONS(state, payload) {
      bindPermission(state, payload, { id: 7, name: 'update_contacts' });
      state.permissions.admin.connectors = payload;
    },

    SET_ADMIN_FIELDS_PERMISSIONS(state, payload) {
      bindPermission(state, payload, { id: 7, name: 'update_contacts' });
      state.permissions.admin.fields = payload;
    },

    SET_ADMIN_CUSTOM_FIELDS_CREATE(state, payload) {
      bindPermission(state, payload, findPermission('create_customefields'));
      state.permissions.admin.customFields.create = payload;
    },

    SET_ADMIN_CUSTOM_FIELDS_EDIT(state, payload) {
      bindPermission(state, payload, findPermission('update_customefields'));
      state.permissions.admin.customFields.edit = payload;
    },

    SET_ADMIN_CUSTOM_FIELDS_DELETE(state, payload) {
      bindPermission(state, payload, findPermission('delete_customefields'));
      state.permissions.admin.customFields.delete = payload;
    },

    SET_ADMIN_ROlES_CREATE(state, payload) {
      bindPermission(state, payload, findPermission('create_roles'));
      state.permissions.admin.roles.create = payload;
    },

    SET_ADMIN_ROlES_EDIT(state, payload) {
      bindPermission(state, payload, findPermission('update_roles'));
      state.permissions.admin.roles.edit = payload;
    },

    SET_ADMIN_ROlES_DELETE(state, payload) {
      bindPermission(state, payload, findPermission('delete_roles'));
      state.permissions.admin.roles.delete = payload;
    },

    SET_ADMIN_ORGANIZATIONS_VIEW(state, payload) {
      bindPermission(state, payload, findPermission('read_organizations'));
      state.permissions.admin.organizations.view = payload;
    },

    SET_ADMIN_ORGANIZATIONS_CREATE(state, payload) {
      bindPermission(state, payload, findPermission('create_organizations'));
      state.permissions.admin.organizations.create = payload;
    },

    SET_ADMIN_ORGANIZATIONS_EDIT(state, payload) {
      bindPermission(state, payload, findPermission('update_organizations'));
      state.permissions.admin.organizations.edit = payload;
    },

    SET_ADMIN_ORGANIZATIONS_DELETE(state, payload) {
      bindPermission(state, payload, findPermission('delete_organizations'));
      state.permissions.admin.organizations.delete = payload;
    },

    SET_ADMIN_TEAMS_VIEW(state, payload) {
      bindPermission(state, payload, findPermission('read_teams'));
      state.permissions.admin.teams.view = payload;
    },

    SET_ADMIN_TEAMS_CREATE(state, payload) {
      bindPermission(state, payload, findPermission('create_teams'));
      state.permissions.admin.teams.create = payload;
    },

    SET_ADMIN_TEAMS_EDIT(state, payload) {
      bindPermission(state, payload, findPermission('update_teams'));
      state.permissions.admin.teams.edit = payload;
    },

    SET_ADMIN_TEAMS_DELETE(state, payload) {
      bindPermission(state, payload, findPermission('delete_teams'));
      state.permissions.admin.teams.delete = payload;
    },

    SET_ADMIN_DIVISIONS_VIEW(state, payload) {
      bindPermission(state, payload, findPermission('read_divisions'));
      state.permissions.admin.divisions.view = payload;
    },

    SET_ADMIN_DIVISIONS_CREATE(state, payload) {
      bindPermission(state, payload, findPermission('create_divisions'));
      state.permissions.admin.divisions.create = payload;
    },

    SET_ADMIN_DIVISIONS_EDIT(state, payload) {
      bindPermission(state, payload, findPermission('update_divisions'));
      state.permissions.admin.divisions.edit = payload;
    },

    SET_ADMIN_DIVISIONS_DELETE(state, payload) {
      bindPermission(state, payload, findPermission('delete_divisions'));
      state.permissions.admin.divisions.delete = payload;
    },

    SET_ADMIN_USERS_CREATE(state, payload) {
      bindPermission(state, payload, findPermission('create_users'));
      state.permissions.admin.users.create = payload;
    },

    SET_ADMIN_USERS_EDIT(state, payload) {
      bindPermission(state, payload, findPermission('update_users'));
      state.permissions.admin.users.edit = payload;
    },

    SET_ADMIN_USERS_DELETE(state, payload) {
      bindPermission(state, payload, findPermission('delete_users'));
      state.permissions.admin.users.delete = payload;
    },

    CLEAR_POST_PERMISSIONS(state, payload) {
      state.postPermissions = payload;
    },
  },

  actions: {
    async fetchPermissions({ commit }) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Security({ url: '/roles/getPermissions', method: 'GET' });
        commit('SET_LOADING', false);
        commit('SET_PERMISSIONS', response.data.message);
        return response.data;
      } catch (error) {
        commit('SET_LOADING', false);
        throw error;
      }
    },

    async fetchRolePermissions({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Security({ url: `/roles/getRole?name=${payload}`, method: 'GET' });
        commit('SET_LOADING', false);
        commit('SET_POST_PERMISSIONS', response.data.message.permissions);
        commit('SET_PERMISSIONS', response.data.message.permissions);
        return response.data;
      } catch (error) {
        commit('SET_LOADING', false);
        throw error;
      }
    },

    async updateRolePermissions({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Security({ url: '/roles/updateByName', data: payload, method: 'PUT' });
        commit('SET_LOADING', false);
        return response.data;
      } catch (error) {
        commit('SET_LOADING', false);
        throw error;
      }
    },

    async asignPermissions({ commit }, payload) {
      commit('SET_LOADING', true);
      try {
        const response = await $http.Security({ url: '/api/permissions/assign', data: payload, method: 'POST' });
        commit('SET_LOADING', false);
        return response.data;
      } catch (error) {
        commit('SET_LOADING', false);
        throw error;
      }
    },
  },
};
