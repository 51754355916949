<template>
  <div id="app">
    <transition
      name="slide-fade"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {

  name: 'App',

};
</script>

<style lang="scss" scoped>
@import '~animate.css/animate.css';

</style>
