import Vue from 'vue';
import Vuex from 'vuex';

// import all vuex modules
import Authentication from './authentication';
import Roles from './roles';
import Permissions from './permissions';
import Organizations from './organizations';
import Users from './users';
import Lookups from './lookups';
import Analytics from './analytics';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    Authentication,
    Roles,
    Permissions,
    Organizations,
    Users,
    Lookups,
    Analytics,
  },

  strict: debug,
});
